import type {PayloadAction} from "@reduxjs/toolkit"
import {createSlice} from "@reduxjs/toolkit"

export interface filterState {
    selectedUsers: string[]
}

const initialState: filterState = {
    selectedUsers: localStorage.getItem("selectedUsers") ? JSON.parse(localStorage.getItem("selectedUsers") as string) : []
}

export interface FilterPayload {
    selectedUsers: string
}

export const userSlice = createSlice({
    name: "usersFilter",
    initialState,
    reducers: {
        addUser: (state, action: PayloadAction<FilterPayload>) => {
            state.selectedUsers.push(action.payload.selectedUsers)
            localStorage.setItem("selectedUsers", JSON.stringify(state.selectedUsers))
        },
        removeUser: (state, action: PayloadAction<FilterPayload>) => {
            state.selectedUsers = state.selectedUsers.filter((user) => user !== action.payload.selectedUsers)
            localStorage.setItem("selectedUsers", JSON.stringify(state.selectedUsers))
        }
    },
})

// Action creators are generated for each case reducer function
export const {addUser, removeUser} = userSlice.actions

export default userSlice.reducer