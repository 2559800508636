// @ts-nocheck
import AppointmentButton from "./AppointmentButton";
import * as React from "react";
import moment from "moment";
import {useNavigate} from "react-router";
import {useEffect} from "react";
import {useInViewport} from "react-in-viewport";
import {IconButton} from "@mui/material";
import AddComment from "@mui/icons-material/AddComment";
import CommentModal from "../../features/planning/CommentModal";
import AppointmentModalButton from "./AppointmentModalButton";


interface Props {
    appointments: any[];
    date: Date;
}

const AppointmentDate = ({appointments, date, setAppointmentId}: Props) => {

    const navigate = useNavigate()
    const ref = React.useRef(null)

    const {
        inViewport,
        enterCount,
        leaveCount,
    } = useInViewport(ref, {}, {}, {});



    useEffect(() => {
        const element = document.getElementById(`week${moment(date).format('w')}`)
        if (inViewport) {
            element?.scrollIntoView({block: "center", inline: "center"})
        }

    }, [inViewport]);

    return (
        <div style={{
            background: moment(date).isSame(moment(), "day") ? "#f6e2b8" : null,
        }}>
            <p ref={ref} style={{scrollMarginTop: 150, marginTop: 16, marginBottom: 0}}
                   id={moment(date).format('w')} className={moment(date).format('DD-MM-YYYY')}>
                    {moment(date).locale('nl').format('dddd DD-MM-yyyy')}
                    <CommentModal date={date}/>
            </p>
                {
                    appointments.map((appointment: any, index) => {
                        return (
                            <AppointmentModalButton
                                key={index}
                                appointment={appointment}
                                marginTop={index > 0 ? 1 : 0}
                                navigate={navigate}
                                setAppointmentId={setAppointmentId}
                            />
                        )
                    })
                }
        </div>
    )
}

export default AppointmentDate;