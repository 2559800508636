// @ts-nocheck
import * as React from 'react';
import {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import * as yup from "yup"
import AppLayout from '../../features/layout/AppLayout';
import {useNavigate, useParams} from 'react-router';
import axiosInstance from '../../xhr/axiosInstance';
import {Button, Grid, Card, IconButton, Dialog} from "@mui/material";
import Link from "@mui/material/Link";
import LoadingOverlay from "../../components/LoadingOverlay";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import style from '../../styles/Planning.module.css'
import signStyle from '../../styles/Sign.module.css'
import {FETCH_INTERVAL} from "../../xhr/xhr.constants";
import moment from "moment";
import "moment/locale/nl";

import AttachmentModal from "../../features/planning/AttachmentModal";
import {CheckOutlined, EditOutlined, LocationOnOutlined, PhoneOutlined, SaveOutlined} from "@mui/icons-material";
import {TextareaField} from "@meierij-it/react-components-v2";
import {useForm} from "react-hook-form";
import {defaultSaveMessage} from "../../helpers/messages";
import ClockOutConfirmModal from "../../features/planning/ClockOutConfirmModal";
import TaskEditModal from "./TaskEditModal";
import HourCorrectionModal from "./HourCorrectionModal";

const schema = yup
    .object({
        name: yup.string().required(),
    })
    .required()

export default function AppointmentDetailModal(props) {
    const navigate = useNavigate();
    const { id, onClose } = props;
    const [data, setData] = React.useState({});
    const [isClocking, setIsClocking] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isClockable, setIsClockable] = React.useState(false);
    const {
        reset,
        register,
        control,
        handleSubmit,
        formState: {errors},
    } = useForm();

    function checkIsClockable(entry) {
        const isDateToday = moment(entry.start_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
        const isUserAssigned = typeof window !== undefined && entry.employee_username === localStorage.getItem('username')
        setIsClockable(isDateToday && isUserAssigned)
    }


    async function fetchEntry() {
        if (!id) return;
        setIsLoading(true)
        axiosInstance.get(`/entries/${id}/`).then((response) => {
            setData(response.data)
            setIsClocking(response.data.is_currently_clocking)
            reset({
                remarks: response.data.remarks
            })
            checkIsClockable(response.data)            //     let address =
            //         `${response.data.address ?? ''} ${response.data.city ?? ''} ${response.data.zip_code ?? ''} ${response.data.country ?? ''}`
            //     setDestination(address)
            // }
        }).finally(() => {
            setIsLoading(false)
        })
    }


    useEffect(() => {
        fetchEntry()
        const interval = setInterval(() => fetchEntry(), 60000);
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [id])

    const signClickHandler = () => {
        navigate(`/delivery/${id}`)
    }

    const clock = () => {
        setIsLoading(true)
        axiosInstance.post(`/entries/${id}/clock_entry/`).then((response) => {
            if (response.status === 200) {
                setIsClocking(!isClocking)
            }
        }).catch((error) => console.log(error)).finally(() => setIsLoading(false));
    };

    function doSave(values) {
        axiosInstance.patch(`/entries/${id}/`, {
                remarks: values?.remarks
            }
        ).then((response) => {
            defaultSaveMessage()
        })
    }

    function hydrateValues(values) {
        return {
            ...values,
        }
    }


    const clockInbutton =
        isClocking !== undefined &&
            <div className={style.clockButton}>
                {isClocking ? <div>
                        <p className={style.clocking}>Je bent nu uren aan het klokken</p>
                        <Button variant='outlined' onClick={clock}>Stop werktijd</Button>
                    </div> :
                    <div>
                        <p className={style['not-clocking']}>Je bent geen uren aan het klokken</p>
                        <Button variant='outlined' onClick={clock}>Start werktijd</Button>
                    </div>
                }
            </div>

    const address =
        <>
            {data?.work_address && <><Typography component="p" gutterBottom fontWeight={'light'} margin={0} ml={1}>
                Adres
            </Typography>
                <Card variant='outlined' sx={{padding: '16px',}}>
                    <Link href={`https://www.google.com/maps/dir/?api=1&destination=${data?.work_address}`}
                          target="_blank"
                          width={'100%'} underline={'none'} color={'orange'}>
                        {data?.work_address} <LocationOnOutlined/>  <br/>
                        {/*{data?.address}, {data?.zip_code}, {data?.city}*/}
                    </Link>
                </Card></>
            }
        </>


    const loader = isLoading && <LoadingOverlay/>

    return (
        <Dialog open={!!id} onClose={onClose} fullScreen maxWidth="md">
            {loader}

            {isClocking && localStorage.getItem('username') == data.employee_username ?
                <ClockOutConfirmModal clock={clock} />
                :
                    <button onClick={onClose} className={signStyle.link}><ArrowBackIcon style={{color: '#000'}}/></button>
            }
            {/*header with client name*/}
            <Typography component="p" gutterBottom textAlign={'center'} mx={{'font-size': '1.2rem'}}
                        fontWeight={'bold'}>
                {data?.client_name} {data?.project_number /*project_num?*/}
            </Typography>

            <Grid container direction={"column"} position={'relative'} mt={3} spacing={2}>
                {address}
                <Grid item mt={2}>
                    <Typography component="p" gutterBottom fontWeight={'light'} margin={0} ml={1}>
                        Opdrachtdetails
                        <span>
                            {localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).is_staff ?
                                <TaskEditModal task={data} refresh={fetchEntry}/> : ""}
                        </span>
                    </Typography>
                    <Card variant='outlined' sx={{padding: '16px', 'font-size': '0.9rem'}}>
                        <Typography component="p" gutterBottom fontWeight={'light'}
                                    sx={{'font-size': '0.8rem'}}>
                            Omschrijving
                        </Typography>
                        <Typography component="p" sx={{}}>{data.description}</Typography>
                        <p>{data.remarks_for_machinist} </p>
                        {data?.assigned_telehandler_display} <br/>
                        {data?.assigned_transport_display} <br/>
                        {data?.fittings?.length ?
                        <ul style={{margin: '0.2rem'}}>
                            {data.fittings.map((fitting) => <li>{fitting.label} </li>)}
                        </ul>
                            : ""}
                            <Grid container direction={"row"} justifyContent={"space-evenly"} alignItems="flex-end">
                                <Grid item xs={6}>
                                <Typography component="p" gutterBottom fontWeight={'light'} mt={1}
                                            sx={{'font-size': '0.8rem'}}>
                                    Datum
                                </Typography>
                                {data?.start_date ? `${moment(data.start_date).locale("nl").format("dd DD-MM-YYYY")}` : ""}
                                <Typography component="p" gutterBottom fontWeight={'light'} mt={1}
                                            sx={{'font-size': '0.8rem'}}>
                                    Geplande Tijd
                                </Typography>
                                {data?.start_time} - {data?.end_time}
                                {data?.employee_username === localStorage.getItem('username')
                                    || JSON.parse(localStorage.getItem('user')).is_staff ?
                                    <>
                                    <Typography component="p" gutterBottom fontWeight={'light'} mt={1}
                                            sx={{'font-size': '0.8rem'}}>
                                        Ingevulde tijd
                                    </Typography>
                                        <HourCorrectionModal
                                        entry={data}
                                        refresh={fetchEntry}/>
                                    </>
                                        : null
                                    }
                            </Grid>
                                <Grid item xs={6}>

                                </Grid>
                            </Grid>

                            {data?.remarks &&
                                <Typography component="p" gutterBottom fontWeight={'light'} mt={1}
                                            sx={{'font-size': '0.8rem'}}>
                                    Opmerking
                                </Typography>
                            }
                            {data?.remarks}

                            <Typography component="p" gutterBottom fontWeight={'light'} mt={1}
                                        sx={{'font-size': '0.8rem'}}>
                                Contact
                            </Typography>
                        <Grid container
                              direction="row"
                              justifyContent="space-between"
                              alignItems="center">
                            <Grid item>
                                {data?.project_contact_name}
                            </Grid>
                            <Grid item>
                                {data?.project_contact_phone ?
                                    <a href={`tel:${data?.project_contact_phone}`}>
                                        <Button
                                            variant='outlined'><PhoneOutlined/> {data?.project_contact_phone}</Button>
                                    </a> :
                                    <IconButton disabled><PhoneOutlined/></IconButton>
                                }
                            </Grid>
                        </Grid>
                        <Grid item>
                            <AttachmentModal taskId={data?.task} numberOfAttachments={data?.number_of_attachments}/>
                        </Grid>
                        <Grid item xs={12}>
                    <div style={{marginTop: '24px'}}>
                        <form onSubmit={handleSubmit(doSave)}>
                            <Grid container direction="row">
                                <Grid item xs={10}>
                                    <TextareaField
                                        size="small"
                                        name="remarks"
                                        label="Opmerking"
                                        control={control}
                                        defaultValue={data?.remarks}
                                        {...register("remarks")}

                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton variant="contained" type="submit">
                                        <SaveOutlined fontSize="large" color="orange"/>
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </form>
                    </div>
                </Grid>
                    </Card>
                    <Button sx={{width: '100%', 'margin-top': '8px', background: data?.delivery_slip_signed ? '#3ea44d' :'orange', padding: '12px 4px'}}
                            variant={'contained'}
                            onClick={signClickHandler}
                            disabled={!data?.is_latest_entry_of_delivery_slip || data?.employee_username !== localStorage.getItem('username')}

                                       >Werkbon
                        {data?.delivery_slip_signed ?
                            <CheckOutlined sx={{marginLeft:'5px'}}/> : null
                        }
                    </Button>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*        {isClockable ? clockInbutton : null}*/}
                {/*</Grid>*/}
            </Grid>
        </Dialog>
    );
}
