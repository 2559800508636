import axiosInstance from "./axiosInstance";

export function axiosGetDownload(url:string, filename:string, params:{} = {}) {
    axiosInstance
        .get(url, {
            params: params,
            responseType: "blob",
        })
        .then((response) => {
            const objectURL = window.URL.createObjectURL(response.data);
            const link = document.createElement("a");

            link.href = objectURL;

            link.setAttribute("download", filename);
            link.click()
            window.URL.revokeObjectURL(objectURL)
        });
}