import { configureStore } from "@reduxjs/toolkit"
import notifications from "../features/filters/notificationsSlice"
import usersFilter from "../features/filters/userSlice"

const store = configureStore({
  reducer: {
    notifications: notifications,
    usersFilter: usersFilter,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store