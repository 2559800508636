import axios from 'axios'
import { ANONYMOUS_TOKEN, API_URL } from './xhr.constants';
import {Store} from "@reduxjs/toolkit"
let store: Store // eslint-disable-line no-unused-vars


function getUniqueDeviceID() {
  // @ts-ignore
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function getDeviceID() {
  if (typeof window !== 'undefined') {
    const deviceId = localStorage.getItem("deviceID");

    if (!deviceId) {
      const uniqueID = getUniqueDeviceID()
      localStorage.setItem("deviceID", uniqueID)
      return uniqueID;
    } else {
      return deviceId
    }
  }
}

// Either use localStorage userToken or anonymous token
function getAuthToken() {
  if (typeof window !== 'undefined' && localStorage.getItem("userToken") !== null) {
    return localStorage.getItem("userToken")
  }

  return ANONYMOUS_TOKEN;
}

const axiosInstance = axios.create({
  baseURL: API_URL, headers: {
    Authorization: "Token " + getAuthToken()
  }, params: {
    uuid: getDeviceID()
  }
});

export const injectStore = (_store: Store) => {
  store = _store // eslint-disable-line @typescript-eslint/no-unused-vars
}

// axiosInstance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   // No access
//   if (error.response.status === 401) {
//     window.location.href = "/login"
//   }
//   return error;
// });

export default axiosInstance;
