import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    ImageList,
    ImageListItem, ImageListItemBar,
} from "@mui/material"
import React, {useCallback, useEffect} from "react"
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {useDisclosure} from "../../hooks/useDisclosure";
import axiosInstance from "../../xhr/axiosInstance";
import AttachmentOutlined from "@mui/icons-material/AttachmentOutlined";

export interface IProps {
    taskId: string
    numberOfAttachments: number
}

export default function AttachmentModal(props: IProps) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {taskId, numberOfAttachments} = props
    const [attachments, setAttachments] = React.useState([])
    const [openedAttachment, setOpenedAttachment] = React.useState(null)

    const fetchFiles = useCallback(async () => {
        if (isOpen && taskId) {
            axiosInstance.get(`/files/?content_type=schedule:task&object_id=${taskId}`).then((response) => {
                setAttachments(response.data.results)
            })
        }
    }, [taskId, isOpen])


    useEffect(() => {
        console.log('fetching files')
        fetchFiles()
    }, [taskId, isOpen]);

    const openAttachment = useCallback((url: string) => {
        window.open(url, "_blank")
    }, [])

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={onOpen}
                disabled={!taskId }
            >
                Bijlagen {numberOfAttachments ? `(${numberOfAttachments})` : ''}
            </Button>

            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            Bijlagen
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    <ImageList
                        rowHeight={160}
                        cols={3}
                        style={{width: 300}}
                    >
                        {attachments.map((item: {
                            id: number,
                            preview: string,
                            name: string,
                            file: string
                        }) => (
                            <ImageListItem key={item.id}>
                                <img src={item.preview ?? item.file} alt={"pdf"}/>
                                <ImageListItemBar
                                    title={item.name}
                                    position="below"
                                    actionIcon={<Grid container justifyContent="space-between"
                                                      spacing={6} direction="row">
                                        <Grid item xs={6}>
                                            <Button onClick={() => openAttachment(item.file)}>
                                                <OpenInNewIcon/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    }
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </DialogContent>
            </Dialog>
        </>
    )
}
