export function timeToDecimal(time: string){
    const [hours, minutes] = time.split(":");
    let parsedTime = parseInt(hours)
    if (minutes) parsedTime += Number(minutes) / 60;
    return Math.round(parsedTime * 100) / 100;
}

export function decimalToTime(time: number|null){
    if (!time) return "-";
  const isNegative = time < 0;
  const absoluteTime = Math.abs(time);
  const hours = Math.floor(absoluteTime);
  const minutes = Math.round((absoluteTime - hours) * 60).toString().padStart(2, "0");
  const formattedTime = `${hours.toString().padStart(2,"0")}:${minutes}`;
  return isNegative ? `-${formattedTime}` : formattedTime;
}

