import axiosInstance from "../../xhr/axiosInstance";
import {IProps} from "./TaskCreateModal";
import {useCallback} from "react";

export async function fetchClients (query: IProps){
        const {data} = await axiosInstance.get("/clients/",
            {
                params:
                    {
                        query: query,
                        no_page: true,
                    }
            })

        return data.map((x: { name: string, id: string }) => ({
            label: x.name,
            value: x.id,
        }))
    }

    export async function fetchEmployees (query: IProps)  {
        const {data} = await axiosInstance.get("/employees/",
            {
                params:
                    {
                        query: query,
                        version: 2,
                    }
            })
        return data.results.map((x: { name: string, id: number }) => ({
            label: x.name,
            value: x.id,
        }))
    }