// @ts-nocheck
import React from 'react';

import style from './WeekFilterButton.module.css';
import {Button, Grid} from "@mui/material";
import moment from "moment";
import Typography from "@mui/material/Typography";


interface Props {
    week: string;
    start: string;
    end: string;
    click: (weekNumber: number) => void;
    isActive?: boolean;
}


const WeekFilterButton = ({ week, start, end, click, isActive}: Props) => {
    function getWeekBackgroundColor(){
        if (isActive){
            return '#cccccc'
        }
        if (moment().isoWeek() === parseInt(week)) {
                    return '#f6e2b8'
        }
        return '#fff'
    }

    const clickHandler = () => {
        console.log(start)
        click(start);
    }

    return (
        <Button
            id={`week${week}`}
            variant={'text'}
            className={style.weekContainerButton}
            onClick={clickHandler}
            style={{backgroundColor: getWeekBackgroundColor(), margin: '0px', padding: '1px', color: '#606060'}}

        >
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    Week {week}
                </Grid>
                <Grid item xs={12} sx={{fontSize:'9px'}}>
                    {start.startOf('isoWeek').format("ddd DD MMM")} - {end}
                </Grid>
            </Grid>
        </Button>
    )
}

export default WeekFilterButton;