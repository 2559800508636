import {AsyncSelectField} from "@meierij-it/react-components-v2";
import {Chip, Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment/moment";
import {useForm} from "react-hook-form";
import axiosInstance from "../../xhr/axiosInstance";

interface IFitting {
    value: string
    label: string
}

interface IProps {
    fittings: IFitting[]
    setFittings: (fittings: IFitting[]) => void
}

export default function FittingSelector(props: IProps) {
    const {fittings, setFittings} = props

    const {
        register,
        control,
        getValues,
        watch,
        formState: {errors, isDirty, isSubmitting},
    } = useForm()

    const watchFittings = watch("fittings")

    useEffect(() => {
        addFitting(watchFittings)
    }, [watchFittings])

    const fetchFittings = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/equipment/fittings/",
            {
                params:
                    {
                        query: query,
                        start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : null,
                        start_time: getValues("start_time"),
                        end_time: getValues("end_time"),
                    }
            })
        return data.results.map((x: {display_name: string, id:number }) => ({
            label: x.display_name,
            value: x.id,
        }))
    }, [])

    function addFitting(fitting: IFitting) {
        if (fitting && fitting.value && !fittings.find((x: IFitting) => x.value === fitting.value)) {
            setFittings([...fittings, fitting])
        }
    }

    function handleDeleteFitting(value: string) {
        setFittings(fittings.filter((fitting: IFitting) => fitting.value !== value))
    }

    return (
        <>
            <Grid item xs={6}>
                <AsyncSelectField
                    error={errors.fittings}
                    label={"Voeg hulpstuk toe"}
                    control={control}
                    isClearable={true}
                    onSearch={fetchFittings}
                    loadOptions={fetchFittings}
                    {...register("fittings")}
                />
            </Grid>
            <Grid item xs={6}>
                {fittings?.length ? fittings.map((fitting: IFitting) => (
                    <Chip key={fitting.value} label={fitting.label}
                          onDelete={() => handleDeleteFitting(fitting.value)}/>
                )) : null}
            </Grid>
        </>
    )
}
