import {
    Accordion, AccordionDetails, AccordionSummary,
    Alert,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, ImageList, ImageListItem, ImageListItemBar, Snackbar,

} from "@mui/material"

import React, {useCallback, useEffect, useState} from "react"
import CloseIcon from '@mui/icons-material/Close';
import {useDisclosure} from "../../hooks/useDisclosure";
import * as yup from "yup"
import {yupResolver} from "@hookform/resolvers/yup"

import {FixTypeLater} from "react-redux";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import {FieldValues, useForm} from "react-hook-form";
import {
    AsyncSelectField,
    CheckboxField,
    DateField,
    InputField,
    StaticSelectField,
    TimeField
} from "@meierij-it/react-components-v2";
import moment from "moment";
import axiosInstance from "../../xhr/axiosInstance";
import FittingSelector from "./FittingSelector";
import Typography from "@mui/material/Typography";
import AddOutlined from "@mui/icons-material/AddOutlined";
import {defaultErrorMessage} from "../../helpers/messages";
import {fetchEmployees} from "./queries";
import {useQueryClient} from "react-query";

const weekDays = [
    {value: 0, label: "Maandag"},
    {value: 1, label: "Dinsdag"},
    {value: 2, label: "Woensdag"},
    {value: 3, label: "Donderdag"},
    {value: 4, label: "Vrijdag"},
    {value: 5, label: "Zaterdag"},
    {value: 6, label: "Zondag"},
]

export interface IProps {
    activeWeek: number
}

interface IFitting {
    value: string
    label: string
}

interface IFile {
    content_type: number,
    file: string,
    file_type: string,
    id: number,
    object_id: number,
    preview: string | null,

}

const schema = yup.object().shape({
    parent_company: yup.object().required("Administratie is verplicht"),
    employee: yup.object().required("Medewerker is verplicht"),
    start_date: yup.date().required("Startdatum is verplicht"),
    start_time: yup.string().required("Starttijd is verplicht"),
    end_time: yup.string().required("Eindtijd is verplicht"),
}).required();


export default function TaskCreateModal(props: IProps) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {activeWeek} = props
    const [fittings, setFittings] = useState<IFitting[] | []>([])
    const [attachments, setAttachments] = useState<IFile[]>([])
    const inputRef = React.useRef(null);
    const [validationError, setValidationError] = useState<string | null>(null);
    const {
        register,
        control,
        reset,
        getValues,
        handleSubmit,
        setValue,
        watch,
        formState: {errors,}
    } = useForm();
    const queryClient = useQueryClient()

    useEffect(() => {
        setValidationError(null)
        reset({})
    }, [isOpen]);

    const watchClient = watch("client")
    const watchRepeated = watch("repeated_task")


    const fetchParentCompanies = useCallback(async () => {
        const {data} = await axiosInstance.get("/parent-companies/")

        return data.results.map((x: { abbreviation: string, id: string }) => ({
            label: x.abbreviation,
            value: x.id,
        }))
    }, [])

    function repeatedDays() {        //   for each weekday field in the form add value of day if checked
        //   return array of days

        const checkedDays: number[] = []
        weekDays.map((day) => {
            if (getValues(`repeat_on_week_day_${day.value}`)) {
                checkedDays.push(day.value)
            }
        })
        return checkedDays
    }

    const fetchClients = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/clients/",
            {
                params:
                    {
                        query: query,
                        no_page: true,
                    }
            })

        return data.map((x: { name: string, id: string }) => ({
            label: x.name,
            value: x.id,
        }))
    }, [])

    const fetchProjects = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/projects/",
            {
                params:
                    {
                        client: watchClient?.value ?? null,
                        query: query,
                        no_page: true,
                    }
            })

        return data.map((x: {
            name: string,
            id: string,
            location: string,
            contact_name: string,
            contact_phone: string
        }) => ({
            label: x.name,
            value: x.id,
            location: x.location,
            contact_name: x.contact_name,
            contact_phone: x.contact_phone,
        }))
    }, [watchClient])

    const fetchTelehandlers = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/equipment/telehandlers/",
            {
                params:
                    {
                        query: query,
                        start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : null,
                        start_time: getValues("start_time"),
                        end_time: getValues("end_time"),
                    }
            })
        return data.results.map((x: { display_name: string, id: number }) => ({
            label: x.display_name,
            value: x.id,
        }))
    }, [])

    const fetchTransport = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/equipment/transports/",
            {
                params:
                    {
                        query: query,
                        start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : null,
                        start_time: getValues("start_time"),
                        end_time: getValues("end_time"),
                    }
            })
        return data.results.map((x: { display_name: string, id: number }) => ({
            label: x.display_name,
            value: x.id,
        }))
    }, [])

    function hydrateValues(values: FixTypeLater) {
        return {
            ...values,
            ...(values.client && {
                client: values.client.value,
            }),
            ...(values.project && {
                project: values.project.value,
            }),
            ...(values.assigned_telehandler && {
                assigned_telehandler: values.assigned_telehandler.value
            }),
            ...(values?.assigned_transport && {
                assigned_transport: values.assigned_transport.value
            }),
            ...(fittings.length ? {
                fittings: fittings.map((fitting: FixTypeLater) => {
                    return fitting.value
                })
            } : null),
            ...(values.parent_company && {
                parent_company: values.parent_company.value
            }),
            ...(values.via_rental && {
                via_rental: values.via_rental.value
            }),
            employee: values.employee ? values.employee.value : null,
            country: values?.country?.value,
            // status: getValues('is_confirmed') ? "CONFIRMED" : null,
            ...(values?.project && {
                project: values.project.value,
            }),
            ...(values.repeated_task?.value && {
                repeated_task: values.repeated_task?.value,
            }),
            repeat_on_days: repeatedDays(),
            end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD") : moment(values.start_date).format("YYYY-MM-DD"),

        }
    }


    function onFinish(doConfirm: boolean) {
        setValidationError(null)
        const values = getValues()
        schema.validate(values).catch((err) => {
            return setValidationError(err.message)
        })
        axiosInstance.post(`/entries/repeated_task/`, {...hydrateValues(values), ...(doConfirm && {status: "CONFIRMED"})}).then((response) => {
            queryClient.invalidateQueries(['appointments', activeWeek ?? moment().week()])
            onClose()
        }).catch((err) => {
            defaultErrorMessage("Er is een fout opgetreden")
        })
    }

    function isStaff() {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '')
            return user?.is_staff
        } catch (e) {
            return false;
        }
    }

    return (
        <>
            {isStaff() ?
                <>
                    <Button
                        variant="outlined"
                        onClick={onOpen}
                        sx={{marginTop: '8px'}}
                    >
                        <AddOutlined/> Nieuwe taak
                    </Button>

                    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
                        <DialogTitle>
                            Taak aanmaken
                        </DialogTitle>
                        {validationError ? <Alert severity="error">{validationError}</Alert> : null}
                        <form>
                            <DialogContent dividers>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <AsyncSelectField
                                            isRequired
                                            error={errors?.parent_company}
                                            label="Administratie"
                                            control={control}
                                            isClearable={false}
                                            loadOptions={fetchParentCompanies}
                                            {...register("parent_company")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AsyncSelectField
                                            isRequired
                                            name="employee"
                                            error={errors.employee}
                                            label="Machinist"
                                            control={control}
                                            isClearable={false}
                                            loadOptions={fetchEmployees}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DateField
                                            isRequired
                                            control={control}
                                            label="(Start)datum"
                                            {...register("start_date")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TimeField
                                            isRequired
                                            control={control}
                                            label="Starttijd"
                                            {...register("start_time")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TimeField
                                            isRequired
                                            control={control}
                                            label="Eindtijd"
                                            {...register("end_time")}
                                            style={{width: '200px'}}

                                        />
                                    </Grid>
                                    <Grid item>
                                        <AsyncSelectField
                                            error={errors?.client}
                                            label="Klant"
                                            control={control}
                                            isClearable={true}
                                            onSearch={fetchClients}
                                            loadOptions={fetchClients}
                                            {...register("client")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AsyncSelectField
                                            error={errors?.project}
                                            label="Project"
                                            control={control}
                                            isClearable={true}
                                            onSearch={fetchProjects}
                                            loadOptions={fetchProjects}
                                            {...register("project")}
                                        />
                                    </Grid>

                                </Grid>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreOutlined/>}>
                                        <Typography variant="subtitle1">Materiaal</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container direction="column" spacing={1}>
                                            <Grid item>
                                                <AsyncSelectField
                                                    error={errors.assigned_telehandler}
                                                    label="Verreiker"
                                                    control={control}
                                                    isClearable={true}
                                                    onSearch={fetchTelehandlers}
                                                    loadOptions={fetchTelehandlers}
                                                    multi={true}
                                                    {...register("assigned_telehandler")}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <AsyncSelectField
                                                    error={errors.assigned_transport}
                                                    label="Transport"
                                                    control={control}
                                                    isClearable={true}
                                                    onSearch={fetchTransport}
                                                    loadOptions={fetchTransport}
                                                    multi={true}
                                                    {...register("assigned_transport")}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FittingSelector fittings={fittings} setFittings={setFittings}/>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreOutlined/>}>
                                        <Typography variant="subtitle1">Herhaling</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <StaticSelectField
                                            error={errors?.repeated_task}
                                            label="Herhaling"
                                            options={[
                                                {label: "Dagelijks", value: "daily"},
                                                {label: "Wekelijks", value: "weekly"},
                                                // {label: "Maandelijks", value: "monthly"},
                                            ]}
                                            control={control}
                                            isClearable={true}
                                            // disabled={eventInfo?.event}
                                            {...register("repeated_task")}
                                        />
                                        {watchRepeated?.value === "weekly" ?
                                            <Grid container direction="row" spacing={1}>
                                                {weekDays.map((day) => (
                                                    <Grid item xs={6} key={day.value}>
                                                        <CheckboxField
                                                            // disabled={eventInfo.event}
                                                            label={day.label}
                                                            control={control}
                                                            {...register(`repeat_on_week_day_${day.value}`)}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            : null}
                                        {watchRepeated ?
                                            <DateField
                                                label="Einddatum"
                                                error={errors?.end_date}
                                                control={control}
                                                value={null}
                                                {...register("end_date")}
                                            />
                                            : null}
                                    </AccordionDetails>
                                </Accordion>
                            </DialogContent>
                            <DialogActions>
                                <Grid container spacing={2} direction="row" alignItems="center"
                                      justifyContent="space-between">
                                    <Grid item xs={12} md={4}>
                                        <Button variant="outlined" color="primary" onClick={onClose}>Annuleren</Button>
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        <Button variant="outlined" color="primary"
                                                onClick={() => onFinish(false)}>Opslaan</Button>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                        <Button variant="contained" color="primary" onClick={() => onFinish(true)}>Opslaan
                                            en
                                            bevestigen</Button>
                                    </Grid>
                                </Grid>
                            </DialogActions>
                        </form>

                    </Dialog>

                </>
                : null}
        </>
    )
}
