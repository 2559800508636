import store from "../redux/store"

export function defaultSaveMessage(): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      message: "Je wijzigingen zijn opgeslagen.",
      type: "success",
    },
  })
}

export function defaultDeleteMessage(): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      message: "Record is verwijderd.",
      type: "success",
    },
  })
}

export function defaultErrorMessage(description?: string): void {
  store.dispatch({
    type: "notifications/showMessage",
    payload: {
      message: "An error occured.",
      type: "error",
    },
  })
}