import React from "react"
import { FieldValues, useForm } from "react-hook-form"
import { useNavigate } from "react-router"
import { InputField, PasswordField } from "@meierij-it/react-components-v2"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import axiosInstance from "../../../xhr/axiosInstance";
import { defaultErrorMessage } from "../../../helpers/messages"

export default function LoginForm() {
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isDirty, isSubmitting },
  } = useForm()

  const getUserDetails = async () => {
    axiosInstance.get('/auth/user').then((response) => {
      localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
    })
  }

  const onFinish = async (values: FieldValues) => {
    axiosInstance.post("auth/login/", {
      username: values.username,
      password: values.password,
    }).then(response => {
      if (response.data.key) {
        axiosInstance.defaults.headers.common["Authorization"] = 'Token ' + response.data.key;
        localStorage.setItem("username", values.username);
        localStorage.setItem("userId", response.data.u);
        localStorage.setItem("userToken", response.data.key);

        axiosInstance.post('/auth/login/', {
            username: values.username,
            password: values.password,
        }).then((response) => {
          localStorage.setItem('permissions', JSON.stringify(response.data.permissions));

          window.location.href = "/"
        }).catch(response => alert("!"));
      }
    }).catch((error) => {
      console.log(error)
      defaultErrorMessage(error?.response?.data?.detail)
    })
  }

  return (
    <form onSubmit={handleSubmit(onFinish)}>
      <Grid container direction="column" spacing="4">
        <Grid item>
          <InputField
            label="Username"
            control={control}
            error={errors.username}
            {...register("username")}
          />
        </Grid>

        <Grid item>
          <PasswordField
            label="Password"
            control={control}
            error={errors.password}
            {...register("password")}
          />
        </Grid>

        <Grid item sx={{ marginTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            sx={{ float: "right" }}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}