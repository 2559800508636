import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import Login from './pages/login';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {injectStore} from "./xhr/axiosInstance";
import store from "./redux/store"
import Home from "./pages/Home";
import Filters from "./pages/Filters";
import AppointmentDetail from './features/planning/AppointmentDetail';
import Sign from "./features/planning/delivery/Sign";
import {QueryClient, QueryClientProvider} from "react-query";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />
  },
    {
        path: "/",
        element: <Home />,
    },
    {
      path: "/filters",
    element: <Filters />,
    },
    {
        path: "/planning/:id",
        element: <AppointmentDetail />,
    },
    {
        path: "/delivery/:id",
        element: <Sign />,
    }

]);

injectStore(store)

const queryClient = new QueryClient()


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
             <RouterProvider router={router} />
          </Provider>
        </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
