import {Accordion, Alert, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import * as React from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import {useDisclosure} from "../../hooks/useDisclosure";
import {FixTypeLater} from "react-redux";
import axiosInstance from "../../xhr/axiosInstance";
import {axiosGetDownload} from "../../xhr/axiosDownloads";
import {json} from "node:stream/consumers";
import {getUser} from "../auth/services/user.service";


export default function WeekStateDialog(props: FixTypeLater) {
    const {week} = props

    function downloadWeekstate(){
        axiosGetDownload(`/employees/${getUser().id}/download_weekstate_pdf/`, "weekstaat.pdf", {
            // get monday of week
            date: week ? week.format('YYYY-MM-DD') : null
        })

    }

    return (
        <>
            <Button sx={{width: '120%', margin: 0}}><Alert severity="info"
                   sx={{width: '100%', margin: 0, paddingTop: "56px"}}
                   onClick={downloadWeekstate}>Weekstaat downloaden</Alert></Button>
            {/*<Dialog open={isOpen} onClose={onClose} maxWidth="xl" fullScreen={true}>*/}
            {/*    <DialogTitle>*/}
            {/*        <Grid container justifyContent="space-between">*/}
            {/*            <Grid item>*/}
            {/*                Weekstaat voor week {moment(week).isoWeek()}*/}
            {/*            </Grid>*/}
            {/*            <Grid item>*/}
            {/*                <IconButton aria-label="close" onClick={onClose}>*/}
            {/*                    <CloseIcon/>*/}
            {/*                </IconButton>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </DialogTitle>*/}

            {/*    <DialogContent dividers>*/}
            {/*        <Grid container direction="column" spacing={2}>*/}
            {/*            {weekState && weekState.day_states?.length ?*/}
            {/*                weekState.day_states.map((dayState: FixTypeLater) => {*/}
            {/*                    return (*/}
            {/*                        <Grid item>*/}
            {/*                            <Grid container direction="row" spacing={1}>*/}
            {/*                                <Grid item xs={12}>*/}
            {/*                                    <Alert severity="info">*/}
            {/*                                    {moment(dayState.date).locale("nl").format("dddd")}*/}
            {/*                                        <strong>Uren: {dayState.verified_hours ?? dayState.machinst_hours}</strong>*/}
            {/*                                        </Alert>*/}
            {/*                                </Grid>*/}
            {/*                                <Grid item xs={4}>*/}
            {/*                                    /!*{formatTime('start')}*!/*/}
            {/*                                   start {dayState.verified_start_time ?? dayState.machinist_start_time}*/}
            {/*                                </Grid>*/}
            {/*                                <Grid item xs={4}>*/}
            {/*                                    einde {dayState.verified_end_time ?? dayState.machinist_end_time}*/}
            {/*                                </Grid>*/}
            {/*                                <Grid item xs={4}>*/}
            {/*                                   pauze {dayState.verified_pause ?? dayState.machinist_pause}*/}
            {/*                                </Grid>*/}
            {/*                            </Grid>*/}

            {/*                        </Grid>*/}
            {/*                    )*/}
            {/*                })*/}
            {/*                : "Geen uren ingevoerd"}*/}
            {/*        </Grid>*/}
            {/*    </DialogContent>*/}
            {/*</Dialog>*/}
        </>
    )
}
