import {
    Accordion, AccordionDetails, AccordionSummary, Alert,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, ImageList, ImageListItem, ImageListItemBar, TextField,

} from "@mui/material"

import React, {useCallback, useEffect, useState} from "react"
import CloseIcon from '@mui/icons-material/Close';
import {useDisclosure} from "../../hooks/useDisclosure";

import {FixTypeLater} from "react-redux";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import {FieldValues, useForm} from "react-hook-form";
import {
    AsyncSelectField,
    CheckboxField,
    DateField,
    InputField,
    StaticSelectField,
    TimeField
} from "@meierij-it/react-components-v2";
import moment from "moment";
import axiosInstance from "../../xhr/axiosInstance";
import FittingSelector from "./FittingSelector";
import Typography from "@mui/material/Typography";
import {DeleteOutlined} from "@mui/icons-material";
import {fetchClients, fetchEmployees} from "./queries";
import {decimalToTime, timeToDecimal} from "../../helpers/parsers";

const weekDays = [
    {value: 0, label: "Maandag"},
    {value: 1, label: "Dinsdag"},
    {value: 2, label: "Woensdag"},
    {value: 3, label: "Donderdag"},
    {value: 4, label: "Vrijdag"},
    {value: 5, label: "Zaterdag"},
    {value: 6, label: "Zondag"},
]

export interface IProps {
    entry: FixTypeLater
    refresh: () => void
}


export default function HourCorrectionModal(props: IProps) {
    const {isOpen, onOpen,  onClose} = useDisclosure()
    const {refresh, entry} = props
    const inputRef = React.useRef(null);
    const {
        register,
        control,
        reset,
        getValues,
        handleSubmit,
        setValue,
        watch,
        formState: {errors}
    } = useForm();


function timeToDateTime(time: string) {
    if (!entry?.start_date || !time) return;
    const dateTime = entry.start_date + " " + time
    return dateTime
}


    const [start, end, break_time] = watch(["corrected_start_time", "corrected_end_time", "break_time"])

    useEffect(() => {
        if (start && end) {
            const start_time = moment(start, "HH:mm")
            const end_time = moment(end, "HH:mm")
            let total_corrected_hours = end_time.diff(start_time, "minutes") / 60
            if (break_time) {
                total_corrected_hours = total_corrected_hours - timeToDecimal(break_time)
            }
            console.log(total_corrected_hours)
            setValue('total_corrected_hours', decimalToTime(total_corrected_hours))
        }

    }, [start, end, break_time])

    useEffect(() => {
        if (entry && isOpen) {
            reset(dehydrateValues())
        }
        else if (isOpen) {
            reset({
                corrected_start_time: null,
                corrected_end_time: null,
                break_time: "00:45"
            })
        }
    }, [entry, isOpen]);


    function hydrateValues(values: FixTypeLater) {
        return {
            corrected_start_time: timeToDateTime(values?.corrected_start_time),
            corrected_end_time: timeToDateTime(values?.corrected_end_time),
            break_time: timeToDecimal(values?.break_time),
            total_corrected_hours: timeToDecimal(values?.total_corrected_hours),
        }
    }

    function dehydrateValues() {
        return {
            corrected_start_time: entry?.corrected_start_time ? moment(entry.corrected_start_time).format("HH:mm") : null,
            corrected_end_time: entry?.corrected_end_time ? moment(entry?.corrected_end_time).format("HH:mm") : null,
            break_time: entry?.break_time ? decimalToTime(entry.break_time).toString() : "00:45",
            total_corrected_hours: entry?.total_corrected_hours ? decimalToTime(entry.total_corrected_hours).toString() : null,
        }
    }

    useEffect(() => {
        console.log(errors)
    }, [errors]);

    function onFinish(values: FieldValues) {
        axiosInstance.patch(`/entries/${entry?.id}/`, hydrateValues(values)).then((response) => {
            refresh()
            onClose()
        })
    }

    function entryLocked() {
        console.log("checking entry is locked");
        if (!entry || entry.is_invoiced) return true
        return false;
    }

    return (
        <>
            <Button
                variant={"outlined"}
                onClick={onOpen}
                disabled={!entry}
            >
                {!entry?.corrected_start_time || !entry?.corrected_end_time ? <EditOutlined/> :
                    <>{moment(entry.corrected_start_time).format("HH:mm")} - {moment(entry.corrected_end_time).format("HH:mm")}</>
                }
                </Button>

            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(onFinish)}>
                                <Grid container direction="column" spacing={1}>
                                    {entryLocked() ?
                                        <Grid item>
                                            <Alert severity="warning">Deze taak is al gefactureerd, uren kunnen niet meer aangepast worden</Alert>
                                        </Grid>
                                        : null
                                    }
                                    <Grid item>
                                        <TimeField
                                            disabled={entryLocked()}
                                            control={control}
                                            label="Starttijd"
                                            {...register("corrected_start_time")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TimeField
                                            disabled={entryLocked()}
                                            control={control}
                                            label="Eindtijd"
                                            {...register("corrected_end_time")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TimeField
                                            disabled={entryLocked()}
                                            control={control}
                                            label="Pauze"
                                            {...register("break_time")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TimeField
                                            disabled={entryLocked()}
                                            control={control}
                                            label="Totaal"
                                            {...register("total_corrected_hours")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Annuleren</Button>
                    <Button disabled={entryLocked()} onClick={handleSubmit(onFinish)}>Opslaan</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
