// @ts-nocheck

import * as React from "react";
import WeekFilterButton from "./WeekFilterButton";
import moment from "moment";
import "moment/locale/nl";


import style from './Weekselector.module.css';
import {useEffect} from "react";

interface Props {
    dateRange: Date[];
    click: (weekNumber: number) => void;
    activeWeek: number;
}

const WeekSelector = ({dateRange, click, activeWeek}: Props) => {
    const startOfPreviousWeek = moment(dateRange[0]).subtract(1, 'week');
    // add one week, and if it is the end of the year, add one year

    const startOfNextWeek = moment(dateRange[0]).add(1, 'week');
    return (
        <div className={style.weekSelector}>
            {dateRange.length ?
                <>
                    <WeekFilterButton
                            key={startOfPreviousWeek}
                            week={startOfPreviousWeek.week().toString()}
                            start={startOfPreviousWeek}
                            end={startOfPreviousWeek.endOf('isoWeek').format("ddd DD MMM")}
                            click={click}
                        />
                        <WeekFilterButton
                            key={dateRange[0]}
                            week={moment(dateRange[0]).isoWeek().toString()}
                            start={moment(dateRange[0])}
                            end={moment(dateRange[0]).endOf('isoWeek').format("ddd DD MMM")}
                            click={click}
                            isActive={true}
                        />
                    <WeekFilterButton
                            key={startOfNextWeek}
                            week={startOfNextWeek.isoWeek().toString()}
                            start={startOfNextWeek}
                            end={startOfNextWeek.endOf('isoWeek').format("ddd DD MMM")}
                            click={click}
                        />
            </> : null}
        </div>
    )

}
export default WeekSelector;