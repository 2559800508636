// @ts-nocheck
import * as React from "react"
import LoginForm from "../features/auth/components/LoginForm"
import Box from "@mui/material/Box"
import AppLayout from "../features/layout/AppLayout"
import { Grid, Typography } from "@mui/material"

export default function Page() {
  return (
    <AppLayout>
      {/*<Head>*/}
      {/*  <title>Login</title>*/}
      {/*</Head>*/}

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        // style={{ minHeight: '100vh' }}
      >

        <Grid item xs={3}>
          <Typography variant='h5'>Login</Typography>
        </Grid>
      </Grid>

      <Box sx={{
        marginTop: "5vh"
      }}>
        <LoginForm />
      </Box>
    </AppLayout>
  )
}