import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton, ImageList, ImageListItem, ImageListItemBar, TextField,

} from "@mui/material"

import React, {useCallback, useEffect, useState} from "react"
import CloseIcon from '@mui/icons-material/Close';
import {useDisclosure} from "../../hooks/useDisclosure";

import {FixTypeLater} from "react-redux";
import EditOutlined from "@mui/icons-material/EditOutlined";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import {FieldValues, useForm} from "react-hook-form";
import {
    AsyncSelectField,
    CheckboxField,
    DateField,
    InputField,
    StaticSelectField,
    TimeField
} from "@meierij-it/react-components-v2";
import moment from "moment";
import axiosInstance from "../../xhr/axiosInstance";
import FittingSelector from "./FittingSelector";
import Typography from "@mui/material/Typography";
import {DeleteOutlined} from "@mui/icons-material";
import {fetchClients, fetchEmployees} from "./queries";

const weekDays = [
    {value: 0, label: "Maandag"},
    {value: 1, label: "Dinsdag"},
    {value: 2, label: "Woensdag"},
    {value: 3, label: "Donderdag"},
    {value: 4, label: "Vrijdag"},
    {value: 5, label: "Zaterdag"},
    {value: 6, label: "Zondag"},
]

export interface IProps {
    task: FixTypeLater
    numberOfAttachments: number
    refresh: () => void
}

interface IFitting {
    value: string
    label: string
}

interface IFile {
    content_type: number,
    file: string,
    file_type: string,
    id: number,
    object_id: number,
    preview: string | null,

}

function ProjectRateGrid(props: { type: string, projectId: any }) {
    return null;
}

export default function TaskEditModal(props: IProps) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {task, numberOfAttachments, refresh} = props
    const [fittings, setFittings] = useState<IFitting[] | []>([])
    const [attachments, setAttachments] = useState<IFile[]>([])
    const inputRef = React.useRef(null);
    const {
        register,
        control,
        reset,
        getValues,
        handleSubmit,
        setValue,
        watch,
        formState: {errors}
    } = useForm();

    function getRepeatLabel(value: string) {
        if (value === "daily") {
            return "Dagelijks"
        }
        if (value === "weekly") {
            return "Wekelijks"
        }
    }

    function repeatedDays() {        //   for each weekday field in the form add value of day if checked
        //   return array of days

        const checkedDays: number[] = []
        weekDays.map((day) => {
            if (getValues(`repeat_on_week_day_${day.value}`)) {
                console.log(day.value)
                checkedDays.push(day.value)
            }
        })
        return checkedDays
    }

    const watchClient = watch("client")


    const fetchTelehandlers = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/equipment/telehandlers/",
            {
                params:
                    {
                        query: query,
                        start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : null,
                        start_time: getValues("start_time"),
                        end_time: getValues("end_time"),
                    }
            })
        return data.results.map((x: { display_name: string, id: number }) => ({
            label: x.display_name,
            value: x.id,
        }))
    }, [])

    const fetchTransport = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/equipment/transports/",
            {
                params:
                    {
                        query: query,
                        start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : null,
                        start_time: getValues("start_time"),
                        end_time: getValues("end_time"),
                    }
            })
        return data.results.map((x: { display_name: string, id: number }) => ({
            label: x.display_name,
            value: x.id,
        }))
    }, [])

    useEffect(() => {
        if (task && task.id) {
            reset(dehydrateValues(task))
            setFittings(task.fittings)
            fetchFiles(task.id)
        }
    }, [task]);

    const watchRepeated = watch("repeated_task")


    function hydrateValues(values: FixTypeLater) {
        return {
            ...values,
            id: task.id,
            ...(values.client && {
                client: values.client.value,
            }),
            ...(values.project && {
                project: values.project.value,
            }),
            ...(values.assigned_telehandler && {
                assigned_telehandler: values.assigned_telehandler.value
            }),
            assigned_transport: values.assigned_transport?.value ?? 'none',
            ...(fittings.length ? {
                fittings: fittings.map((fitting: FixTypeLater) => {
                    return fitting.value
                })
            } : null),
            ...(values.parent_company && {
                parent_company: values.parent_company.value
            }),
            ...(values.via_rental && {
                via_rental: values.via_rental.value
            }),
            employee: task.employee,
            country: values?.country?.value,
            // status: getValues('is_confirmed') ? "CONFIRMED" : null,
            ...(values?.project && {
                project: values.project.value,
            })
        }
    }

    function dehydrateValues(values: FixTypeLater) {
        return {
            ...values,
            ...(values?.country && {
                country: {
                    label: values.country,
                    value: values.country,
                },
            }),
            ...(values?.client && {
                client: {
                    value: values.client,
                    label: values.client_name,
                }
            }),
            ...(values?.project && {
                project: {
                    value: values.project,
                    label: values.project_display,
                    location: values.project_location,
                    contact_name: values.project_contact_name,
                    contact_phone: values.project_contact_phone
                }
            }),
            ...(values.parent_company && {
                parent_company: {
                    value: values.parent_company,
                    label: values.parent_company_abbreviation,
                }
            }),
            ...(values.via_rental && {
                via_rental: {
                    value: values.via_rental,
                    label: values.via_rental_display,
                }
            }),
            ...(values?.assigned_telehandler && {
                assigned_telehandler: {
                    value: values.assigned_telehandler,
                    label: values.assigned_telehandler_display,
                }
            }),
            ...(values?.assigned_transport && {
                assigned_transport: {
                    value: values.assigned_transport,
                    label: values.assigned_transport_display,
                }
            }),
            ...(values?.employee && {
                employee: {
                    value: values.employee,
                    label: values.employee_display,
                }
            }),
            ...(values?.task_info?.repeated_task && {
                repeated_task: {
                    value: values.task_info.repeated_task,
                    label: getRepeatLabel(values.task_info.repeated_task),
                }
            }),

            ...(values?.task_info?.end_date && {
                end_date: moment(values.task_info.end_date).toDate(),
            }),
            fittings: null,
            is_confirmed: values.status === "CONFIRMED",
        }
    }

    const fetchFiles = useCallback(async (taskId: number) => {
        axiosInstance.get(`/files/?content_type=schedule:task&object_id=${taskId}`).then((response) => {
            setAttachments(response.data.results)
        })
    }, [])

    useEffect(() => {
        console.log(errors)
    }, [errors]);

    function onFinish(values: FieldValues) {
        axiosInstance.patch(`/entries/${task.id}/`, hydrateValues(values)).then((response) => {
            refresh()
            onClose()
        })
    }

    const fetchProjects = useCallback(async (query: IProps) => {
        const {data} = await axiosInstance.get("/projects/",
            {
                params:
                    {
                        client: watchClient?.value ?? null,
                        query: query,
                        no_page: true,
                    }
            })

        return data.map((x: {
            name: string,
            id: string,
            location: string,
            contact_name: string,
            contact_phone: string
        }) => ({
            label: x.name,
            value: x.id,
            location: x.location,
            contact_name: x.contact_name,
            contact_phone: x.contact_phone,
        }))
    }, [watchClient])

    function doSaveEmployee(updateAll: boolean) {
        console.log(task.task_info)
        axiosInstance.post(`entries/repeated_task/`, {
            id: task.id,
            employee: getValues("employee")?.value ? getValues("employee")?.value : null,
            update_all: updateAll,
            repeated_task: task.task_info?.repeated_task,
            repeat_on_days: task.task_info?.repeat_on_days ?? []
        })
            .then((response) => {
                refresh()
                onClose()
            })
    }

    useEffect(() => {
        if (task?.task_info?.repeat_on_days) {
            console.log(task.task_info.repeat_on_days)
            task.task_info.repeat_on_days.forEach((day: string) => {
                    setValue(`repeat_on_week_day_${day}`, true)
                }
            )
        }
    }, [task]);

    function doSaveAppointmentDetails(updateAll: boolean) {
        axiosInstance.post(`entries/repeated_task/`, {
            id: task.id,
            start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : null,
            start_time: getValues("start_time"),
            end_time: getValues("end_time"),
            update_all: updateAll,
            repeated_task: task.task_info?.repeated_task,
            repeat_on_days: task.task_info?.repeat_on_days ?? []
        })
            .then((response) => {
                refresh()
                onClose()
            })
    }

    function doSaveEquipmentDetails(updateAll: boolean) {
        axiosInstance.post(`entries/repeated_task/`, {
            id: task.id,
            assigned_telehandler: getValues("assigned_telehandler")?.value ?? null,
            assigned_transport: getValues("assigned_transport")?.value ?? 'none',
            update_all: updateAll,
            repeated_task: task.task_info?.repeated_task,
            repeat_on_days: task.task_info?.repeat_on_days ?? []
        })
            .then((response) => {
                refresh()
                onClose()
            })
    }

    function handleUpload(file: FileList) {
        if (!task.id) return;
        let formData = new FormData();
        formData.append("file", file[0]);
        formData.append("file_type", "ATTACHMENT");
        formData.append("object_id", task.task.toString());
        formData.append("content_type", "schedule:task")

        axiosInstance
            .post(`/files/`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                setAttachments([...attachments, response.data])
            });
    }

    function doSaveSpecifics(updateAll: boolean) {
        axiosInstance.post(`entries/repeated_task/`, {
            id: task.id,
            update_all: updateAll,
            repeated_task: task.task_info?.repeated_task,
            repeat_on_days: task.task_info?.repeat_on_days ?? [],
            description: getValues("description"),
            client: getValues("client")?.value ?? null,
            project: getValues("project")?.value ?? null,
        }).then((response) => {
            refresh()
            onClose()
        })
    }

    function doSaveRepetition(updateAll: boolean) {
        axiosInstance.post(`entries/repeated_task/`, {
            id: task.id,
            update_all: updateAll,
            start_date: getValues("start_date") ? moment(getValues("start_date")).format("YYYY-MM-DD") : moment(task.start_date).format("YYYY-MM-DD"),
            end_date: getValues("end_date") ? moment(getValues("end_date")).format("YYYY-MM-DD") : null,
            repeated_task: getValues("repeated_task")?.value ?? null,
            repeat_on_days: repeatedDays(),
        })
            .then((response) => {
                refresh()
                onClose()
            })
    }

    const handleDeleteAttachment = useCallback((id: number) => {
        axiosInstance.delete(`/files/${id}/`).then(() => {
                setAttachments(attachments.filter((attachment) => attachment.id !== id))
            }
        )
    }, [attachments])

    return (
        <>
            <IconButton
                onClick={onOpen}
                disabled={!task.id}
            >
                <EditOutlined/>
            </IconButton>

            <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
                <DialogContent dividers>
                    <form onSubmit={handleSubmit(onFinish)}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined/>}>
                                <Typography variant="subtitle1">Machinist wijzigen</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <form>
                                    <Grid container direction="column" spacing={1}>
                                        <Grid item>
                                            <AsyncSelectField
                                                name="employee"
                                                error={errors.employee}
                                                label="Machinist"
                                                control={control}
                                                isClearable={false}
                                                loadOptions={fetchEmployees}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction="row" alignItems="center"
                                                  justifyContent="space-between">
                                                <Grid item>
                                                    <Button variant="contained" onClick={() => doSaveEmployee(false)}>
                                                        Opslaan
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="contained" onClick={() => doSaveEmployee(true)}>
                                                        Opslaan voor alle toekomstige taken
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </AccordionDetails>
                        </Accordion>
                         <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined/>}>
                                <Typography variant="subtitle1">Datum / Tijd wijzigen</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <TimeField
                                            control={control}
                                            label="Starttijd"
                                            {...register("start_time")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TimeField
                                            control={control}
                                            label="Eindtijd"
                                            {...register("end_time")}
                                            style={{width: '200px'}}

                                        />
                                    </Grid>
                                    <Grid item>
                                        <DateField
                                            control={control}
                                            label="Datum"
                                            {...register("start_date")}
                                            style={{width: '200px'}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveAppointmentDetails(false)}>
                                            Opslaan
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveAppointmentDetails(true)}>
                                            Opslaan voor alle toekomstige taken
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined/>}>
                                <Typography variant="subtitle1">Materiaal wijzigen</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <AsyncSelectField
                                            error={errors.assigned_telehandler}
                                            label="Verreiker"
                                            control={control}
                                            isClearable={true}
                                            onSearch={fetchTelehandlers}
                                            loadOptions={fetchTelehandlers}
                                            multi={true}
                                            {...register("assigned_telehandler")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AsyncSelectField
                                            error={errors.assigned_transport}
                                            label="Transport"
                                            control={control}
                                            isClearable={true}
                                            onSearch={fetchTransport}
                                            loadOptions={fetchTransport}
                                            multi={true}
                                            {...register("assigned_transport")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FittingSelector fittings={fittings} setFittings={setFittings}/>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveEquipmentDetails(false)}>
                                            Opslaan
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveEquipmentDetails(true)}>
                                            Opslaan voor alle toekomstige taken
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined/>}>
                                <Typography variant="subtitle1">Project informatie</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <AsyncSelectField
                                            error={errors.client}
                                            label="Klant"
                                            control={control}
                                            isClearable={true}
                                            onSearch={fetchClients}
                                            loadOptions={fetchClients}
                                            {...register("client")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <AsyncSelectField
                                            error={errors.project}
                                            label="Project"
                                            control={control}
                                            isClearable={true}
                                            onSearch={fetchProjects}
                                            loadOptions={fetchProjects}
                                            {...register("project")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <TextField
                                            label="Omschrijving"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            {...register("description")}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveSpecifics(false)}>
                                            Opslaan
                                        </Button>
                                    </Grid>

                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveSpecifics(true)}>
                                            Opslaan voor alle toekomstige taken
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreOutlined/>}>
                                <Typography variant="subtitle1">Herhaling</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <StaticSelectField
                                    error={errors?.repeated_task}
                                    label="Herhaling"
                                    options={[
                                        {label: "Dagelijks", value: "daily"},
                                        {label: "Wekelijks", value: "weekly"},
                                        // {label: "Maandelijks", value: "monthly"},
                                    ]}
                                    control={control}
                                    isClearable={true}
                                    // disabled={eventInfo?.event}
                                    {...register("repeated_task")}
                                />
                                {watchRepeated?.value === "weekly" ?
                                    <Grid container direction="row" spacing={1}>
                                        {weekDays.map((day) => (
                                            <Grid item xs={6} key={day.value}>
                                                <CheckboxField
                                                    // disabled={eventInfo.event}
                                                    label={day.label}
                                                    control={control}
                                                    {...register(`repeat_on_week_day_${day.value}`)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    : null}
                                {watchRepeated ?
                                    <DateField
                                        label="Einddatum"
                                        error={errors?.end_date}
                                        control={control}
                                        value={null}
                                        {...register("end_date")}
                                    />
                                    : null}
                                <Grid container direction="row" alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" onClick={() => doSaveRepetition(true)}>
                                            Opslaan voor alle toekomstige taken
                                        </Button>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreOutlined/>}>
                                <Typography>Bijlagen</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container direction="row" spacing={1}>
                                    <Grid item xs={12}>
                                        <input
                                            // accept="image/jpeg,image/png,image/gif"
                                            type="file"
                                            onChange={(e) => e.target.files ? handleUpload(e.target.files) : null}
                                            ref={inputRef}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ImageList
                                            rowHeight={160}
                                            cols={3}
                                            style={{width: 300}}
                                        >
                                            {attachments.map((item: IFile) => (
                                                <ImageListItem key={item.id}>
                                                    <img src={item.preview ?? item.file} alt={"pdf"}/>
                                                    <ImageListItemBar
                                                        // title={item.name}
                                                        position="below"
                                                        actionIcon={<Grid container justifyContent="space-between"
                                                                          spacing={6} direction="row">
                                                            <Grid item xs={6}>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <IconButton
                                                                    onClick={() => handleDeleteAttachment(item.id)}>
                                                                    <DeleteOutlined/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        }
                                                    />

                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Annuleren</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
