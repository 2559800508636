// @ts-nocheck
import React from 'react';
import {CircularProgress} from "@mui/material";


const mainContainerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,

}

const backdropStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.11)',
    zIndex: 999,
}

const LoadingOverlay = () => {
    return (
        <div style={{margin: 0, padding: 0}}>
            <div style={backdropStyle}></div>
            <div style={mainContainerStyle}>
                <CircularProgress />
            </div>
        </div>
    )


}

export default LoadingOverlay;