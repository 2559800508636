// @ts-nocheck
import {Chip, Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import * as React from "react";

const AppointmentModalButton = ({appointment, marginTop, navigate, setAppointmentId}: any) => {
    return (
        <Grid item xs={12} sx={{marginTop, textOverflow: 'ellipsis', overflow: 'hidden'}} key={appointment.id}>
            <Button
                variant="outlined"
                color="primary"
                fullWidth
                sx={{background: '#fff'}}
                onClick={() => setAppointmentId(appointment.id)}
            ><Grid container direction='column' sx={{color: 'black'}}>
                <Grid item xs={2}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        {appointment.is_currently_clocking ?
                            <div
                                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Typography sx={{color: '#01c101', fontWeight: 'bold'}}>
                                    {appointment.start_time} - {appointment.end_time}
                                </Typography>
                                <AccessTimeIcon sx={{height: '18px', color: 'green'}}/>
                            </div>
                            :
                            <Typography sx={{color: 'orange', fontWeight: 'bold'}}>
                                {appointment.start_time} - {appointment.end_time}
                            </Typography>
                        }

                        {appointment.project_display &&
                            <div
                                style={{fontSize: '0.8rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                <LocationOnIcon sx={{height: '18px'}}/>
                                <Typography sx={{fontSize: '0.8rem'}}>
                                    {appointment.project_display}
                                </Typography>
                            </div>
                        }

                    </div>
                </Grid>
                <Grid item xs={8} sx={{marginTop: '4px'}}>
                    <Typography sx={{textAlign: 'left', fontSize: '0.8rem'}}>
                        {appointment.client_name}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <Grid container direction='row' spacing={1}>
                        <Grid item>
                    <div style={{
                                marginTop: '4px',
                                background: appointment.employee_color,
                                borderRadius: '5px',
                                padding: '5px',
                                color: 'white',
                                width: 'fit-content'
                            }}
                        >
                      </div>
                            </Grid>
                        <Grid item>

                    <Typography sx={{textAlign: 'left', fontSize: '0.8rem'}}>

                        {appointment.employee_display}
                    </Typography>
                            </Grid>
                        </Grid>
                </Grid>
            </Grid>
            </Button>
        </Grid>
    )
}

export default AppointmentModalButton;