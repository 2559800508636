import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    ImageList,
    ImageListItem, ImageListItemBar,
} from "@mui/material"
import React, {useCallback, useEffect} from "react"
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {useDisclosure} from "../../hooks/useDisclosure";
import AttachmentOutlined from "@mui/icons-material/AttachmentOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {useNavigate} from "react-router";
import {type} from "os";
import signStyle from "../../styles/Sign.module.css";

export interface IProps {
    clock: () => void;
}

export default function ClockOutConfirmModal(props: IProps) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {clock } = props
    const navigate = useNavigate()
    function doClockOut(){
        clock()
        goBack()
    }

    function goBack(){
        if (typeof window !== 'undefined') {
            navigate('/')
        }
    }

    return (
        <>
            <IconButton
                onClick={onOpen}
            >
                 <div className={signStyle.link}>
                    <ArrowBackIcon/>
                 </div>
            </IconButton>

            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            Uitklokken?
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    Je bent nog ingeklokt. Wil je uitklokken?
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>
                        Annuleren
                    </Button>
                    <Button onClick={doClockOut} >
                        Uitklokken
                    </Button>
                    <Button onClick={()=> goBack()} >
                        Ingeklokt blijven
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
