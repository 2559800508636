import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    ImageList,
    ImageListItem, ImageListItemBar,
} from "@mui/material"
import React, {useCallback, useEffect} from "react"
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {useDisclosure} from "../../hooks/useDisclosure";
import axiosInstance from "../../xhr/axiosInstance";
import AttachmentOutlined from "@mui/icons-material/AttachmentOutlined";
import moment from "moment/moment";
import {AddCommentOutlined} from "@mui/icons-material";
import {TextareaField, TimeField} from "@meierij-it/react-components-v2";
import {useForm} from "react-hook-form";
import {FixTypeLater} from "react-redux";

export interface IProps {
    date: string
}

export default function CommentModal(props: IProps) {
    const {date} = props
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [attachments, setAttachments] = React.useState([])
    const [openedAttachment, setOpenedAttachment] = React.useState(null)
    const {
        register,
        getValues,
        reset,
        control,
        handleSubmit,
        formState: {errors}
    } = useForm();

    useEffect(() => {
        if (isOpen) {
            fetchComment()
        }
    }, [isOpen]);

    function addComment(values: FixTypeLater) {
        if (!localStorage.getItem('username')) return;

        axiosInstance.post('day-states/add_comment/', {
            ...hydrateValues(values),
            date: moment(date).format('YYYY-MM-DD'),
            employee: localStorage.getItem('username')
        }).then(
            (response) => {
                reset({})
                onClose()
            })
    }

    function hydrateValues(values: FixTypeLater) {
        console.log(values.machinist_end_time ? values.machinist_end_time.toString().substring(0,5)+':00' : null)
        return {
            ...values,
            machinist_start_time: values.machinist_start_time ? values.machinist_start_time.toString().substring(0,5)+':00' : null,
            machinist_end_time: values.machinist_end_time ? values.machinist_end_time.toString().substring(0,5)+':00' : null,
        }
    }

    function fetchComment() {
        if (!localStorage.getItem('username')) return;

        axiosInstance.get('day-states/', {
            params: {
                date: moment(date).format('YYYY-MM-DD'),
                employee: localStorage.getItem('username')
            }
        }).then((response) => {
            if (response.data.results.length > 0) {
                console.log(response.data.results[0])
                reset(response.data.results[0])
            }
            else {
                reset({machinist_pause: "00:45"})
            }
        })

    }


    useEffect(() => {

    }, [date, isOpen]);



    return (
        <>
            <IconButton
                onClick={onOpen}
            >
                <AddCommentOutlined/>
            </IconButton>

            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            Dagstaat invullen voor {moment(date).locale('nl').format('dddd D MMMM')}
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>

                <DialogContent dividers>
                    <Grid container direction="column" spacing={2}>
                        <form onSubmit={handleSubmit(addComment)}>
                            <Grid item sx={{marginTop:4}}>
                                <TimeField
                                    label="Starttijd"
                                    required={false}
                                    {...register("machinist_start_time")}
                                    control={control}
                                    error={errors.machinist_start_time}
                                />
                            </Grid>
                            <Grid item sx={{marginTop:2}}>
                                <TimeField
                                    label="Eindtijd"
                                    required={false}
                                    {...register("machinist_end_time")}
                                    control={control}
                                    error={errors.machinist_end_time}
                                />
                            </Grid>
                            <Grid item sx={{marginTop:2}}>
                                <TimeField
                                    label="Pauze"
                                    required={false}
                                    {...register("machinist_pause")}
                                    control={control}
                                    error={errors.machinist_pause}
                                />
                            </Grid>
                            <Grid item sx={{marginTop:2}}>
                                <TextareaField
                                    label="Opmerking"
                                    {...register("machinist_notes")}
                                    control={control}
                                    error={errors.machinist_notes}
                                />
                            </Grid>
                            <Grid item sx={{marginTop:2}}>
                                <Button
                                    // sx={{marginTop: 2}}
                                    variant="contained"
                                    type="submit"
                                >Opslaan</Button>
                            </Grid>
                        </form>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}
