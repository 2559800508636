// @ts-nocheck

import * as React from 'react';
import AppLayout from '../features/layout/AppLayout';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import axiosInstance from "../xhr/axiosInstance";
import moment from "moment";
import {useAppSelector} from "../hooks/redux";
import {FixMeLater} from "../types/generic";
import WeekSelector from "../components/appointment/WeekSelector";
import AppointmentDate from "../components/appointment/AppointmentDate";
import {Alert, Button, Fab, LinearProgress, Switch} from "@mui/material";
import TodayIcon from '@mui/icons-material/Today';
import {Refresh, RefreshOutlined} from "@mui/icons-material";
import {useQuery, useQueryClient} from "react-query";
import TaskCreateModal from "../features/planning/TaskCreateModal";
import WeekStateDialog from "../features/weekstate/WeekStateDialog";
import AppointmentDetailModal from "../features/planning/AppointmentDetailModal";

export default function Home() {
    const navigate = useNavigate()
    const [dateRange, setDateRange] = useState<Date[]>([])
    const [appointments, setAppointments] = useState<any[]>([])
    const {selectedUsers} = useAppSelector((state) => state.usersFilter);
    const [activeWeek, setActiveWeek] = useState<number>(moment().week())
    const [appointmentDates, setAppointmentDates] = useState<any[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [appointmentId, setAppointmentId] = useState<number | null>(null)
    const appointmentsQuery = useQuery(['appointments', activeWeek], () => fetchAppointments())
    const [weekState, setWeekState] = useState<FixMeLater>(null)

    if(!localStorage.getItem('userToken'))
        {
            navigate('/login')
        }

    const queryClient = useQueryClient()
    // TODO do this at login
    async function fetchPermissions() {
        if (!localStorage.getItem('userToken')) return;
        const {data} = await axiosInstance.get('/users/get_current_user')
        if (data) {
           localStorage.setItem('user', JSON.stringify(data))
        }
    }


    async function fetchAppointments() {
        if (!isLoading) {
            const fromDate = moment(activeWeek).startOf('isoWeek').format('YYYY-MM-DD')
            const toDate = moment(activeWeek).endOf('isoWeek').format('YYYY-MM-DD')

            const {data} = await axiosInstance.get('/entries/', {
                params: {
                    from_date: fromDate,
                    to_date: toDate,
                    version: 2,
                    status: "CONFIRMED",
                    list_view: true,
                    group_by: 'employee',
                    employees: JSON.stringify(selectedUsers),
                    limit: 999,
                    hide_inactive_employees: true,
                }
            })
            return data
            // .then((response) => {
            //     if (response.data.results.length > 0) {
            //         const user = localStorage.getItem("username")
            //         const appointments = response.data.results.filter((appointment: FixMeLater) => selectedUsers.includes(appointment.employee))
            //         setAppointments(appointments)
            //     }
            // }).finally(() => {
            //     setIsLoading(false)
            // })
        }
    }

    function fetchWeekState(){
        if (!localStorage.getItem('user')) return;
        axiosInstance.get('/week-states/verified_week_state', {
            params: {
                week: moment(activeWeek).format("YYYY-MM-DD"),
                user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : null
            }
        }).then((response) => {
            setWeekState(response.data)
        })
    }

    useEffect(() => {
        fetchPermissions()
        setActiveWeek(moment())

    }, [])

    useEffect(() => {
        if (appointmentsQuery.data) {
            const appointments = appointmentsQuery.data.results.filter((appointment: FixMeLater) => selectedUsers.includes(appointment.employee))
            setAppointments(appointments)
        }
    }, [appointmentsQuery.data])

    useEffect(() => {
        if (activeWeek){
            fetchWeekState()
        }
    }, [activeWeek])

    useEffect(() => {
        getDatesBetween()
        days()
    }, [appointments]);


    function getDatesBetween() {
        const dates = [];
        if (activeWeek) {
            const startDate = moment(activeWeek).startOf('isoWeek').toDate();
            const endDate = moment(activeWeek).endOf('isoWeek').toDate();
            while (startDate <= endDate) {
                dates.push(new Date(startDate));
                startDate.setDate(startDate.getDate() + 1);
            }
            setDateRange(dates)

        }
        return dates
    }


    const getAppointmentsForDate = (date: Date) => {
        return appointments.filter(x => x.start_date === moment(date).format('YYYY-MM-DD'))
    }


    function days (){
        const dateRange = getDatesBetween()
        const _appointmentDates =
            dateRange.map(date => {
            const appointments = getAppointmentsForDate(date);
                return (
                    <AppointmentDate key={date.getTime()} appointments={appointments} date={date} setAppointmentId={setAppointmentId}/>
                )})
        setAppointmentDates(_appointmentDates)
    }

    const weekFilterClick = (weekNumber: number) => {
        setActiveWeek(weekNumber)
    }

    useEffect(() => {
        const isCurrentWeek = moment().isoWeek() === moment(activeWeek).isoWeek()

        if (isCurrentWeek && document.getElementsByClassName(moment().format('DD-MM-yyyy'))) {
            document.getElementsByClassName(moment().format('DD-MM-yyyy'))[0]?.scrollIntoView(true)
        }
        else {
            document.getElementById(`${activeWeek}`)?.scrollIntoView({block: "center", inline: "center"})
        }
    }, [activeWeek]);

    const todayClick = () => {
        const isCurrentWeek = moment().isoWeek() === moment(activeWeek).isoWeek()

        if (isCurrentWeek && document.getElementsByClassName(moment().format('DD-MM-yyyy'))) {
            document.getElementsByClassName(moment().format('DD-MM-yyyy'))[0]?.scrollIntoView(true)
        }
        else {
            weekFilterClick(moment());
        }
    }

    function onCloseAppointmentModal(){
        setAppointmentId(null)
    }

    return (
        <>
        <AppLayout background={'#f5f5f5'}>
            <WeekSelector dateRange={dateRange} click={weekFilterClick} activeWeek={activeWeek}/>
            {weekState ? <WeekStateDialog week={activeWeek} weekState={weekState}/>: null}
            {appointmentsQuery.isLoading ?
                 <div
                style={{overflowY: 'auto', background: 'transparent', margin: 0, paddingTop: weekState ? 0 : '56px', paddingBottom: '56px'}}>
                     <LinearProgress/>
                 </div>
                :
                <div
                    style={{overflowY: 'auto', background: 'transparent', margin: 0, paddingTop: weekState ? 0 : '56px', paddingBottom: '56px'}}>
                    <TaskCreateModal
                        activeWeek={activeWeek}
                    />
                    {appointmentDates}
                    <AppointmentDetailModal id={appointmentId} onClose={onCloseAppointmentModal}/>
                    {appointmentId}
                </div>
            }

        </AppLayout>
            <Fab style={{position: 'fixed', right: '8px', bottom: '8px', background: 'orange'}} onClick={todayClick}>
                <TodayIcon/>
            </Fab>
            <Fab style={{position: 'fixed', right: '128px', bottom: '8px', background: 'bluegrey'}} onClick={fetchAppointments}>
                <RefreshOutlined/>
            </Fab>
    </>
    );
}
