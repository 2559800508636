// @ts-nocheck

import * as React from "react"
import AppLayout from "../features/layout/AppLayout"
import axiosInstance from "../xhr/axiosInstance";
import {Grid, LinearProgress} from "@mui/material";
import { Card } from "@meierij-it/react-components-v2";
import Typography from "@mui/material/Typography";
import store from "../redux/store";
import { useAppSelector } from "../hooks/redux";
import {useEffect} from "react";

export default function Page() {
    const [employees, setEmployees] = React.useState<any[]>([])
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    async function fetchEmployees(){
        if (!isLoading) {
            setIsLoading(true)

            axiosInstance.get(`/employees/`, {
                params: {
                    is_active: true,
                    limit: 999,
                    version: 2,
                }
            }).then((response) => {
                setEmployees(response.data.results)
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

  const { selectedUsers } = useAppSelector((state) => state.usersFilter);

    function toggleSelectionForEmployee(employeeId: string){

        if (isSelected(employeeId)){
            store.dispatch({
                type: "usersFilter/removeUser",
                payload: {
                 selectedUsers: employeeId,
                }
              });
            // setSelectedEmployees(selectedEmployees.filter((id) => id !== employeeId))
        }else{
            store.dispatch({
                type: "usersFilter/addUser",
                payload: {
                 selectedUsers: employeeId,
                }
              });

            // setSelectedEmployees([...selectedEmployees, employeeId])
        }
    }

    function isSelected(employeeId: string){
        return selectedUsers.includes(employeeId)
    }


    React.useEffect(() => {
        fetchEmployees()
    }, [])

  return (
    <AppLayout>
        <h4>Selecteer medewerkers</h4>
        <Grid container direction="column" spacing={1}>
            {isLoading ? <LinearProgress /> :
            employees?.length  ? employees.map((employee: any) =>
               <Grid item key={employee.id}
                     onClick={()=>toggleSelectionForEmployee(employee.id)}
                     sx={{backgroundColor: ()=>isSelected(employee.id) ? 'orange' : '#adadad'}}               >
                   <Typography>{employee.name}</Typography></Grid>
            ) : <p>Geen medewerkers</p>
            }
        </Grid>
    </AppLayout>
  )
}