// @ts-nocheck

import React, {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from "react-router";
import {
    Button,
    Card,
    Fab,
    Grid,
    LinearProgress, Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import axiosInstance from "../../../xhr/axiosInstance";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SignatureCanvas from "react-signature-canvas";
import CreateIcon from '@mui/icons-material/Create';

import style from '../../../styles/Sign.module.css'
import {Create} from "@mui/icons-material";
import {FixMeLater} from "../../../types/generic";
import Link from "@mui/material/Link";
import {InputField} from "@meierij-it/react-components-v2";
import {useForm} from "react-hook-form";
import {defaultSaveMessage} from "../../../helpers/messages";
import moment from "moment";

const Sign = () => {

    const navigate = useNavigate();
    const {id} = useParams()
    const [data, setData] = React.useState<FixMeLater>({});
    const [machines, setMachines] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [deliverySlip, setDeliverySlip] = React.useState<FixMeLater>(null)
    const signatureRef = React.useRef(null);
    const {
        control,
        register,
        getValues,
        setValue,
        handleSubmit,
        formState: {errors, isDirty},
    } = useForm();

    const fetchEntry = async () => {
        if (!isLoading) {
            setIsLoading(true);
            const response = await axiosInstance.get(`/entries/${id}/`);
            if (response.data) {
                setData(response.data);
                fetchDeliverySlipEntries(response.data.delivery_slip);
                setIsLoading(false);
            }
        }
    }

    function fetchDeliverySlipEntries(deliverySlipId) {
        if (!deliverySlipId) return;
        axiosInstance.get(`/delivery-slips/${deliverySlipId}/show_entry_times`).then(response => {
            setDeliverySlip(response.data);
        })
    }

    function formatIntoPng() {
        if (signatureRef.current) {
            const dataURL = signatureRef.current?.toDataURL();
            return dataURL;
        }
    }

    useEffect(() => {
        if (id) {
            fetchEntry()
        }
    }, [id])

    useEffect(() => {
        setValue(
            'signee_name', data?.signee_name
        )
    }, [data]);

    // Set machines string
    useEffect(() => {
        if (data?.machines) {
            let newMachines = '';
            for (let i = 0; i < data.machines.length; i++) {
                if (i === data.machines.length - 1)
                    newMachines += data.machines[i];
                else
                    newMachines += data.machines[i] + ', ';
            }
            setMachines(newMachines);
        }
    }, [data]);


    const machineString = useCallback(()=> {
        if (!data) return;
        return (<div style={{fontSize: '12px'}}>
            {data?.assigned_telehandler_display} <br/>
            {data?.assigned_transport_display} <br/>
            {data?.fittings?.length ?
                <ul style={{margin: '0.2rem'}}>
                    {data.fittings.map((fitting) => <li>{fitting.label} </li>)}
                </ul>
                : ""}
        </div>)
    }, [])

    function signAndSave() {
        const signature = formatIntoPng();
        if (data.delivery_slip) {
            axiosInstance.post(`/delivery-slips/${data.delivery_slip}/sign/`, {
                signature: signature,
                signee_name: getValues('signee_name')
            }).then(response => {
                navigate(`/planning/${id}`)
            })
        }
    }



    function setEntryTimes() {
        if (!deliverySlip?.length) return;
        const entryTimes = deliverySlip.map((entry) => {
            return (
                <tr key={entry.id}>
                    <td
                        span={6}>{entry.corrected_start_time ? moment(entry.corrected_start_time).locale('nl').format("dd") : "-"}</td>
                    <td
                        span={6}>{entry.corrected_start_time ? moment(entry.corrected_start_time).format("HH:mm") : "-"}</td>
                    <td
                        span={6}>{entry.corrected_end_time ? moment(entry.corrected_end_time).format("HH:mm") : "-"}</td>
                </tr>
            )
        })
        return entryTimes;
    }

    return (
        <div style={{height: '100vh', width: '100vw', display: 'flex', flexDirection: 'column'}}>
            <Link href={'/planning/' + id}>
                <div className={style.link}><ArrowBackIcon/></div>
            </Link>
            <Typography component='h1' variant='h4' align={'center'}>Werkbon tekenen</Typography>
            <Card variant='outlined'
                  sx={{
                      margin: '8px 8px 60px 8px',
                      padding: '8px',
                      flexGrow: 1,
                      display: 'flex',
                      flexDirection: 'column'
                  }}>
                <Grid container style={{display: 'flex'}}
                      alignContent="flex-start">
                    <Grid item xs={12}>
                        <Grid></Grid>
                        <table style={{width: '100%', fontSize: '12px', marginBottom: '5px'}}>
                            <tr style={{fontWeight: 600}}>
                                <td>dag</td>
                                <td>Startijd</td>
                                <td>Eindtijd</td>
                            </tr>
                            {setEntryTimes()}
                        </table>
                    </Grid>
                    {/*<Grid item xs={12} sx={{borderBottom: '1px solid #ccc'}}>*/}
                    {/*    <div>*/}
                    {/*        <span style={{fontSize: '0.9rem'}}>{machineString()}</span><br/>*/}
                    {/*    </div>*/}
                    {/*</Grid>*/}
                </Grid>
                {isLoading ?
                    <LinearProgress/>
                    :
                    data?.delivery_slip_signature ?
                        <div style={{
                            height: '50%',
                            display: 'flex',
                            fontSize: '10px',
                            textAlign: 'center',
                        }}>
                            <img
                                src={data.delivery_slip_signature}
                                style={{height: '100%'}}
                                // className={style.canvasStyle}
                            />
                        </div>
                        :
                        <div className={style.canvasContainer}>
                            {/*<CreateIcon className={style.createIcon}/>*/}

                            <SignatureCanvas
                                penColor='black'
                                canvasProps={{className: style.canvasStyle}}
                                ref={signatureRef}
                            />
                        </div>
                }
                <form>
                    <InputField label="Getekend door"
                                name="signee_name"
                                control={control}
                                error={errors.name}
                                {...register}
                    />
                </form>
            </Card>
            <Button
                disabled={data?.delivery_slip_signed}
                variant='contained'
                style={{position: 'fixed', bottom: 16, left: 16, right: 16}}
                onClick={() => signAndSave()}
            >
                Ondertekenen</Button>
        </div>
    );
}

export default Sign;